import gql from "graphql-tag";

export const GET_OVERVIEW_MAP = gql`
  query GetOverviewMap($uri: ID = "/our-neighborhoods/") {
    tabsDescription: page(idType: URI, id: $uri) {
      id
      ourNeighborhoods {
        fieldGroupName
        modelHomesOverview
        ourNeighborhoodsOverview
        quickMoveInsOverview
      }
    }
    neighborhoods(last: 500) {
      nodes {
        basePrice
        databaseId
        id
        slug
        status
        title
        types
        uri
        collection {
          collection
        }
        neighborhood {
          status
          neighborhoodArea
          address1
          address2
          city
          state
          hideCommunity
          longitude
          latitude
          thumbnail {
            altText
            sourceUrl
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
      }
    }
    plans(last: 500) {
      nodes {
        id
        title
        uri
        floorplanDetails {
          collection
          displayTitle
          neighborhood {
            ... on Neighborhood {
              databaseId
              id
              slug
              title
            }
          }
        }
      }
    }
    models(last: 500) {
      nodes {
        databaseId
        slug
        status
        title
        uri
        collection {
          collection
          fieldGroupName
        }
        featuredImage {
          node {
            altText
            slug
            sourceUrl
            title
            uri
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
        modelDetails {
          latitude
          longitude
          floorplan {
            ... on Plan {
              floorplanDetails {
                bathroomsMax
                bathroomsMin
                bedroomsMax
                bedroomsMin
                collection
                displayTitle
                mainSqrFt
                finishedSqrFt
              }
            }
          }
          neighborhood {
            ... on Neighborhood {
              basePrice
              databaseId
              id
              slug
              title
              types
              neighborhood {
                neighborhoodArea
                status
                thumbnail {
                  altText
                  sourceUrl
                  mediaDetails {
                    sizes {
                      file
                      fileSize
                      height
                      mimeType
                      name
                      sourceUrl
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    homes(last: 500) {
      nodes {
        databaseId
        uri
        title
        slug
        collection {
          collection
          fieldGroupName
        }
        featuredImage {
          node {
            altText
            slug
            sourceUrl
            title
            uri
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
        }
        qmiHomeDetails {
          bathrooms
          bedrooms
          finishedSqrFt
          hideOnMap
          latitude
          longitude
          price
          type
          floorplan {
            ... on Plan {
              title
              floorplanDetails {
                collection
                displayTitle
              }
            }
          }
          neighborhood {
            ... on Neighborhood {
              title
              neighborhood {
                neighborhoodArea
                thumbnail {
                  altText
                  sourceUrl
                  mediaDetails {
                    sizes {
                      file
                      fileSize
                      height
                      mimeType
                      name
                      sourceUrl
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
