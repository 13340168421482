import { mapStyles } from "./mapStyles";

export const defaultCenter = {
  lat: 31.055488685085088,
  lng: -97.48783539273478,
};

export const defaultZoom = 10;

export const mapOptions = {
  center: defaultCenter,
  clickableIcons: false,
  gestureHandling: "cooperative",
  maxZoom: 19,
  minZoom: 9,
  streetViewControl: false,
  styles: mapStyles,
  zoom: defaultZoom,
  zoomControl: true,
};

export const icons = {
  purple: {
    icon: "/img/map-pin-purple.png",
  },
  green: {
    icon: "/img/map-pin-green.png",
  },
};

export const overviewMapOffset = {
  breakpoint: 1024,
  x: -250,
  y: 0,
};

export const mapWithInfoMapOffset = {
  breakpoint: 640,
  x: 200,
  y: 0,
};

export const mapArbitraryMapOffset = {
  breakpoint: 640,
  x: 0,
  y: 0,
};
